@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";

@font-face {
  font-family: Inter;
  font-weight: 200;
  src: url("../assets/fonts/inter/InterExtraLight.ttf") format("ttf");
}
@font-face {
  font-family: Inter;
  font-weight: 300;
  src: url("../assets/fonts/inter/InterLight.ttf") format("ttf");
}
@font-face {
  font-family: Inter;
  font-weight: 400;
  src: url("../assets/fonts/inter/InterRegular.ttf") format("ttf");
}
@font-face {
  font-family: Inter;
  font-weight: 500;
  src: url("../assets/fonts/inter/InterMedium.ttf") format("ttf");
}
@font-face {
  font-family: Inter;
  font-weight: 600;
  src: url("../assets/fonts/inter/InterSemiBold.ttf") format("ttf");
}
@font-face {
  font-family: Inter;
  font-weight: 700;
  src: url("../assets/fonts/inter/InterBold.ttf") format("ttf");
}
@font-face {
  font-family: Inter;
  font-weight: 800;
  src: url("../assets/fonts/inter/InterExtraBold.ttf") format("ttf");
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

/* input[type="date"]::-webkit-inner-spin-button, */
input[type="date"]::-webkit-calendar-picker-indicator {
  background: transparent;
  width: 40px;
  margin-right: -40px;
}

/* input[type="time"]::-webkit-inner-spin-button, */
input[type="time"]::-webkit-calendar-picker-indicator {
  background: transparent;
  width: 40px;
  margin-right: -40px;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* .react-tags__searchx .react-tags__search-inputx,
selectedClass {
	position: relative;
	width: 100%;
	border-radius: 10px;
	-webkit-border-radius: 10px;
	-moz-border-radius: 10px;
	-ms-border-radius: 10px;
	-o-border-radius: 10px;
	border: 1px rgba(112, 110, 110, 0.3) solid;
	outline: none;
}

.tagsClassx .tagInputClassx:focus {
	border: none;
} */

.react-tags {
  position: relative;
  padding: 3px 0 0 3px;
  border: 1px solid #d1d1d1;
  border-radius: 9px;
  font-size: 0.8em;
  color: black;
  font-weight: 500;
  line-height: 1;
  cursor: text;
  -webkit-border-radius: 9px;
  -moz-border-radius: 9px;
  -ms-border-radius: 9px;
  -o-border-radius: 9px;
}

.react-tags.is-focused {
  border-color: #b1b1b1;
}

.react-tags__selected {
  display: inline;
}

.react-tags__selected-tag {
  display: inline-block;
  box-sizing: border-box;
  margin: 0 6px 6px 0;
  padding: 5px 5px;
  border: 1px solid #d1d1d1;
  border-radius: 5px;
  background: #f1f1f1;
  border: none;
  outline: none;
  font-size: inherit;
  line-height: inherit;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
}

.react-tags__selected-tag:after {
  content: "\2715";
  color: #aaa;
  margin-left: 10px;
}

.react-tags__selected-tag:hover,
.react-tags__selected-tag:focus {
  border-color: #dddada;
}

.react-tags__search {
  display: inline-block;
  padding: 7px 2px;
  margin-bottom: 6px;
  max-width: 100%;
}

@media screen and (min-width: 30em) {
  .react-tags__search {
    position: relative;
  }
}

.react-tags__search-input {
  max-width: 100%;
  margin: 0;
  padding: 0;
  border: 0;
  outline: none;
  font-size: inherit;
  line-height: inherit;
  border: none;
}

.react-tags__search-input::-ms-clear {
  display: none;
}

.react-tags__suggestions {
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
}

@media screen and (min-width: 30em) {
  .react-tags__suggestions {
    width: 240px;
  }
}

.react-tags__suggestions ul {
  margin: 4px -1px;
  padding: 0;
  list-style: none;
  background: white;
  border: 1px solid #d1d1d1;
  border-radius: 2px;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.2);
}

.react-tags__suggestions li {
  border-bottom: 1px solid #ddd;
  padding: 6px 8px;
}

.react-tags__suggestions li mark {
  text-decoration: underline;
  background: none;
  font-weight: 600;
}

.react-tags__suggestions li:hover {
  cursor: pointer;
  background: #eee;
}

.react-tags__suggestions li.is-active {
  background: #b7cfe0;
}

.react-tags__suggestions li.is-disabled {
  opacity: 0.5;
  cursor: auto;
}

.article-title,
.article-image {
  max-width: 100%;
  width: 100%;
}

.quill-kom {
  display: flex;
  flex-direction: column-reverse;
  border-top: solid 1px #ccc;
}

.width-select-promo-kom {
  width: 100% !important;
}
