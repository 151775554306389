main {
  min-height: 100vh;
}

.ql-editor{
  min-height: 200px;
}

.ticket-textarea {
  /* border: none; */
  /* outline: 0px !important; */
  border: none;
  resize: none;
  font-size: 14px;
  padding: 0px;
  padding-top: 10px;
  height: 40px;
  max-height: 105px;
  overflow: auto;
  line-height: 18px;
}

.ticket-textarea:focus {
  outline: none;
  outline-offset: 0px;
  --tw-ring-color: white;
}

.table-head {
  min-width: 200px;
}

.table-head-auto {
  min-width: auto !important;
}

.shadow-top {
  box-shadow: 0px -1px 3px rgba(0, 0, 0, 0.1);
}

.text-area-container {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none;
}

.text-area-container::-webkit-scrollbar {
  display: none;
}

.ticket-list-container {
  width: 350px;
  min-width: 300px;
  height: 100%;
  border-right: 2px solid #f4f4f4;
  overflow: auto;
}

.ticket-container {
  height: calc(100vh - 204px);
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  background-color: white;
  border: 1px solid #f4f4f4;
}

.selected-ticket {
  position: relative;
  background-color: #f9fafb;
}

.selected-ticket::before {
  content: "";
  position: absolute;
  left: 0px;
  top: 0px;
  width: 5px;
  height: 100%;
  background-color: rgba(90, 101, 53, 1);
}

.selected-ticket-first-index::before {
  border-top-left-radius: 10px;
}

.ticket-bubble {
  min-width: 100px;
}

.customer-bubble {
  background: #ffffff;
  border: 1px solid #d1d5db;
  margin-left: 1rem;
  border-radius: 0px 20px 20px 20px;
}

.chat-bubble-container {
  max-width: 50%;
}

@media screen and (max-width: 800px) {
  .chat-bubble-container {
    max-width: 80%;
  }
}

.cs-bubble-container {
  flex-direction: row-reverse;
  margin-left: auto;
}

.cs-bubble {
  margin-right: 1rem;
  background-color: rgba(90, 101, 53, 0.1);
  border-radius: 20px 0px 20px 20px;
}

.cs-bubble-container .time {
  margin-right: 1rem;
}

.unread-count {
  width: 20px;
  min-width: 20px;
  height: 20px;
  min-height: 20px;
  border-radius: 100%;
  background-color: #f43f5e;
}

.avatar-img-s {
  position: relative;
  width: 30px;
  height: 30px;
  min-width: 30px;
  min-height: 30px;
  border-radius: 100%;
  object-fit: cover;
  background-color: #616d39;
}

.attachment-icon {
  width: 16px;
  height: 16px;
  min-width: 16px;
  margin: 0 16px;
  cursor: pointer;
}

.close-ticket-btn {
  background-color: #ffffff;
  border: 1px solid #d1d5db;
  color: #4b5563;
  padding: 2px 10px;
}

.file-upload-container {
  background: #fbfbfb;
}

.file-upload-img-container {
  position: relative;
  width: fit-content;
  padding: 8px 10px;
  border-radius: 8px;
  border: 2px solid #e5e7eb;
}

.file-upload-img {
  width: 19px;
  height: 24px;
}

.file-upload-delete-img {
  position: absolute;
  width: 15px;
  height: 15px;
  right: -5px;
  top: -5px;
  cursor: pointer;
}

.pointer {
  cursor: pointer;
}

.preview-image-container {
  position: relative;
  width: 150px;
  margin-right: 10px;
  margin-bottom: 10px;
  border: 1px solid #d1d5db;
  border-radius: 5px;
}

.preview-image {
  width: 100%;
  max-height: 100px;
  border-top-right-radius: 5px;
  border-top-left-radius: 5px;
  object-fit: contain;
}

.preview-delete-container {
  border-radius: 10px;
}

.counter-input-number {
  text-align: center;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

.outline-button {
  background-color: #fff;
  border: 1px solid #d1d5db;
  color: #374151;
}

.mom-description a {
  all: revert;
}

.mom-description menu {
  all: revert;
}

.mom-description ul {
  all: revert;
}

.mom-description ol {
  all: revert;
}

.rich-text .ql-container {
  height: 150px;
}

.create-article-content .ql-container {
  height: 300px;
}

.create-catalog-content .ql-container {
  height: 150px;
}

.display-rich-text ul {
  list-style: unset;
  margin-left: 25px;
}

.display-rich-text ol {
  list-style-type: decimal;
  margin-left: 25px;
}

.display-rich-text a {
  color: #2563eb;
}

.quill-kom .ql-editor {
  max-height: 300px;
  min-height: 50px;
}

.ticket-list-message {
  overflow: hidden;
  text-overflow: ellipsis;
  height: 30px;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.article-label {
  margin-bottom: 20px !important;
}

.notification-box {
  width: 480px;
  max-height: 480px;
  position: absolute;
  left: 50%;
  top: 5%;
  border: 1px solid #d1d5db;
  border-radius: 10px;
  z-index: 900;
  background-color: white;
  overflow: auto;
  box-shadow: 0px 1px 3px 0px #0000001a;
}

.notif-dot {
  width: 10px;
  height: 10px;
  background-color: #358888;
  border-radius: 50%;
  flex: none;
  order: 0;
  flex-grow: 0;
}

.notif-hide {
  visibility: hidden;
}

.notif-show {
  visibility: visible;
}

.notif-date-text {
  color: #6b7280;
}

.preview-image-size {
  max-width: 68px;
  max-height: 68px;
}

.border-radius-50 {
  border-radius: 50%;
}

.vo-accordion-title {
  cursor: pointer;
  width: 100%;
  text-align: left;
  border: none;
  outline: none;
  transition: 0.4s;
}

.vo-accordion-panel {
  overflow: hidden;
}

.before-bullet::before {
  content: "• ";
  margin-right: 0.5rem;
  font-weight: bold;
  font-size: 1.5rem;
  opacity: 0.5;
}

.katalog-form-shadow {
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.1);
}

.katalog-form-radius {
  border-radius: 10px;
}

.search-katalog {
  min-width: 420px;
}

@media (min-width: 1600px) {
  .search-katalog {
    min-width: 600px !important;
  }
}

.rspi-main-bg {
  background-color: #358888;
}

.rspi-main-color {
  color: #358888;
}
